import React from "react"
import BtfData from "./data/btf.json"
import Styles from "./css/btf.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"

class Btf extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: BtfData
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
   else if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
   else if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
   else if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
   else if (getBrowser() === "safari" && browsers.includes("safari")) {
      display = true;
    }
   else if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
      if (isSafari()) {
        const browserData = Object.assign(this.state.data, this.state.data.safari);
        this.setState({ data: browserData });
      }
      if (isIeEdge()) {
        const browserData = Object.assign(this.state.data, this.state.data.edge);
        this.setState({ data: browserData });
      }

      if (typeof window != "undefined") {
      window.mapParams();
    }
    });
  }

  render() {
    return (
      <section style={{ display: this.state.display }}>
      <div className = {Styles.btf} >
        
      <div className = {Styles.btfTop} >
        <img className = {Styles.productImg} src={this.state.data.productImg} />
        <article>
        <h2>{this.state.data.btfHeadlineTop}</h2>
        <h3>{this.state.data.topProductFeat1}</h3>
        <h4>{this.state.data.topProductSubFeat1}</h4>
        <h3>{this.state.data.topProductFeat2}</h3>
        <h4>{this.state.data.topProductSubFeat2}</h4>
        <h3>{this.state.data.topProductFeat3}</h3>
        <h4>{this.state.data.topProductSubFeat3}</h4>
        </article>
        <div className = {Styles.cta}>
        <button onClick={() => window.triggerInstall()} data-cy="cta"
        className={`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span
    dangerouslySetInnerHTML={{__html: this.state.data.cta}}></span>
</button>
{this.props.children}
      </div>
      </div>
      <div className = {Styles.btfBot} >
      <h2 className={Styles.btfHeadlineBot}> {this.state.data.btfHeadlineBot}</h2>
        <article>
        <h5>{this.state.data.botProductFeat1}</h5>
        <h6>{this.state.data.botProductSubFeat1}</h6>
        </article>
        <article>
        <h5>{this.state.data.botProductFeat2}</h5>
        <h6>{this.state.data.botProductSubFeat2}</h6>
        </article>
        <article>
        <h5>{this.state.data.botProductFeat3}</h5>
        <h6>{this.state.data.botProductSubFeat3}</h6>
        </article>
        <div className = {Styles.cta}>
        <button onClick={() => window.triggerInstall()} data-cy="cta"
        className={`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span
    dangerouslySetInnerHTML={{__html: this.state.data.cta}}></span>
</button>
{this.props.children}
      </div>
      </div>
      

            </div>
            {this.props.footer}
          </section>
    )
  }
}

export default Btf


